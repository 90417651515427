import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "./Header/Navbar";
import LightBox from "./LightBox";

import {
	getUserListById
} from "../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ReactPaginate from "react-paginate";
import SimpleReactLightbox from "simple-react-lightbox";


class UserDetail extends Component {
	state = {
		
		LoadingIs: false,
		userListData: [],
	};


	handleCallApi = async 	(id) => {
		let response = await getUserListById(id);
		this.setState({ copyresponse: response.data });
		if (response.status === 1) {
			let res = response.data;
			let showButton = true;
			this.setState({
				userListData: res,
				showButton: showButton,
			});
		} else {
			toast.error(response.msg);
		}

	};

	
	componentDidMount() {

		let userId = this.props?.match?.params?.userid;

		if (userId) {
			this.handleCallApi(userId);
		}
	}

	render() {
		const { userListData } = this.state;
		// console.log(userListData)
		return (
			<>
				<Navbar />

				<SimpleReactLightbox>
					<div className='main-content'>
						<div className='header'>
							<div className='container-fluid'>
								<div className='header-body'>
									<div className='row align-items-end'>
										<div className='col'>
											<h6 className='header-pretitle'> User </h6>
											<h1 className='header-title'> Detail</h1>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='container topInfo'>
							<div className='inline'>
								<h1 style={{ textTransform: "capitalize" }}>
									{userListData?.usersProfile?.firstName} {userListData?.usersProfile?.lastName}
								</h1>
								<p>{userListData?.usersProfile?.gender}</p>
							</div>
							<div className='inline float-right'>
								<span className='fe fe-map-pin fs-3 text-primary inline'></span>{" "}
								<p className='inline location'>{userListData?.usersProfile?.Location ? userListData?.usersProfile?.Location : "NA"}</p>
							</div>
							<LightBox />
						</div>

						<div className='container'>
							<div className='row'>
								<div className='card col-md-12 personalInfo '>
									<h4 className='card-header-title pt-3 my-3 mx-4'>Personal Info</h4>
									<hr />
									<div className='row mx-3 my-3 '>
										<div className='col-md-12 brdr-btm'>
											<div className='form-group pd-1rem' style={{ paddingTop: "0px" }}>
												<label className='textPink' style={{ fontSize: "22px", fontWeight: "600" }}>
													
													Bio
												</label>
												<div
													className=''
													style={{
														textAlign: "justify",
													}}>
													{userListData?.usersProfile?.moreAboutMyself}
												</div>
											</div>
										</div>
										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'> User Id </label>
												<div className=''>{userListData?.usersProfile?.id ?userListData?.usersProfile?.id:"-"}</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'> Phone </label>
												<div className=''>
													{userListData?.usersProfile?.phoneNumber ? userListData?.usersProfile?.phoneNumber : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Sexuality</label>
												<div className=''>
													{userListData?.usersProfile?.sexuality ? userListData?.usersProfile?.sexuality : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Pronouns</label>
												<div className=''>
													{userListData?.usersProfile?.pronouns ? userListData?.usersProfile?.pronouns : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Date of birth</label>
												<div className=''>
													{userListData?.usersProfile?.dateOfBirth ? userListData?.usersProfile?.dateOfBirth : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'> Age</label>
												<div className=''>
													{userListData?.usersProfile?.age ? userListData?.usersProfile?.age : "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 '>
											<div className='form-group pd-1rem'>
												<label className='textPink'> marital status</label>
												<div className=''>
													{userListData?.usersProfile?.maritalStatus
														? userListData?.usersProfile?.maritalStatus
														: "-"}
												</div>
											</div>
										</div>

										<div className='col-md-4 '>
											<div className='form-group pd-1rem'>
												<label className='textPink'> Children</label>
												<div className=''>
													{userListData?.usersProfile?.children ? userListData?.usersProfile?.children : "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-4 '>
											<div className='form-group pd-1rem'>
												<label className='textPink'> Pets</label>
												<div className=''>
													{userListData?.usersProfile?.pets ? userListData?.usersProfile?.pets : "NA"}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='card col-md-12 lifeStyle'>
									<h4 className='card-header-title pt-3 my-3 mx-4'>Lifestyle</h4>
									<hr />
									<div className='row my-3 mx-3'>
										<div className='col-md-3 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Hobbies</label>
												<div className=''>
													{userListData?.usersLifestyle?.Hobbies ? userListData?.usersLifestyle?.Hobbies : "NA"}
												</div>
											</div>
										</div>
										<div className='col-md-3 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Diet</label>
												<div className=''>
													{userListData?.usersLifestyle?.Diet ? userListData?.usersLifestyle?.Diet : "NA"}
												</div>
											</div>
										</div>
										<div className='col-md-3 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Height</label>
												<div className=''>
													{userListData?.usersLifestyle?.Height
														? (userListData?.usersLifestyle?.Height).split(".").join("'") + '"'
														: "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3 brdr-btm'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Body Type</label>
												<div className=''>
													{userListData?.usersLifestyle?.BodyType ? userListData?.usersLifestyle?.BodyType : "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Excercise</label>
												<div className=''>
													{userListData?.usersLifestyle?.Exercise ? userListData?.usersLifestyle?.Exercise : "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Smoking Habits</label>
												<div className=''>
													{userListData?.usersLifestyle?.SmokingHabits
														? userListData?.usersLifestyle?.SmokingHabits
														: "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Drinking Habits</label>
												<div className=''>
													{userListData?.usersLifestyle?.DrinkingHabits
														? userListData?.usersLifestyle?.DrinkingHabits
														: "NA"}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='card col-md-12 career '>
									<h4 className='card-header-title pt-3 my-3 mx-4'>Career</h4>
									<hr />
									<div className='row mx-3 my-3'>
										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>University/College</label>
												<div className=''>
													{userListData?.usersCareer?.University ? userListData?.usersCareer?.University : "NA"}
												</div>
											</div>
										</div>
										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Industry</label>
												<div className=''>
													{userListData?.usersCareer?.Industry ? userListData?.usersCareer?.Industry : "NA"}
												</div>
											</div>
										</div>
										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Language</label>
												<div className=''>
													{userListData?.usersCareer?.Language ? userListData?.usersCareer?.Language : "NA"}
												</div>
											</div>
										</div>

										<div className='col-md-3'>
											<div className='form-group pd-1rem'>
												<label className='textPink'>Vocational Training </label>
												<div className=''>
													{userListData?.usersCareer?.VocationalTraining
														? userListData?.usersCareer?.VocationalTraining
														: "NA"}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<ToastContainer position='top-center' autoClose={2000} />
					</div>
				</SimpleReactLightbox>
			</>
		);
	}
}

export default withRouter(UserDetail);
