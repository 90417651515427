// import { FunctionsSharp } from "@material-ui/icons";
import { AUTH_TOKEN } from "../store/sessionStogrageKey";
import axios from "axios";

const API_BASE_URL = "https://api.kiiqui.com";

const SECOND_BASE_URL = API_BASE_URL;



// const API_BASE_URL = "http://localhost:3001";
// const SECOND_BASE_URL = "http://localhost:3001";

/**
 *
 *@param BASE_URL 1 if @var API_BASE_URL to be use
 */
function makeApiRequest(method, url, body, isAuthorize, BASE_URL = 1) {
  let real_url;
  if (BASE_URL === 1) {
    //  real_url = new URL( url, API_BASE_URL );
    real_url = API_BASE_URL + url;
  } else {
    // real_url = new URL(url, SECOND_BASE_URL );
    real_url = SECOND_BASE_URL + url;
  }
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (isAuthorize) {
    let authToken = localStorage.getItem(AUTH_TOKEN);
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  let options = { method, headers };
  if (body) {
    options["body"] = JSON.stringify(body);
  }
  return fetch(real_url, options)
    .then(async (response) => {
      if (response.status === 401) {
        localStorage.removeItem("authToken");
        window.location.href = "/";
      }
      return response.text().then((text) => {
        // alert(JSON.parse(text).message)
        return text ? JSON.parse(text) : {};
      });
    })
    .catch((error) => {
      /**
       * for Error, status -1 assigned, It's assumed that -1 will not come from
       * server.
       */
      return { msg: error, status: -1 };
    });
}

function uploadFile(method, url, body, isAuthorize, BASE_URL = 1) {
  let real_url;
  if (BASE_URL === 1) {
    real_url = API_BASE_URL + url;
  } else {
    real_url = SECOND_BASE_URL + url;
  }
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (isAuthorize) {
    let authToken = localStorage.getItem(AUTH_TOKEN);
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  let options = { method, headers };
  if (body) {
    options["body"] = JSON.stringify(body);
  }

  return axios.post(real_url, body, options);
}

function uploadFileEdit(method, url, body, isAuthorize, BASE_URL = 1) {
  let real_url;
  if (BASE_URL === 1) {
    real_url = API_BASE_URL + url;
  } else {
    real_url = SECOND_BASE_URL + url;
  }
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (isAuthorize) {
    let authToken = localStorage.getItem(AUTH_TOKEN);
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  let options = { method, headers };
  if (body) {
    options["body"] = JSON.stringify(body);
  }

  return axios.put(real_url, body, options);
}
/**
 * @param stateData should have all inputs that we have in create contest,
 * pass single object with all field enclosed
 *
 */
export function createContest(stateData) {
  return makeApiRequest("POST", "/admin/createContest", stateData, true);
}

export function logIn(email, password) {
  const body = {
    email: email,
    password: password,
  };

  return makeApiRequest("POST", "/admins/login", body, false);
}

/**
 * Need object as argument
 */
export function getContestList(page, limit, name) {
  return makeApiRequest(
    "GET",
    `/admin/contestList?page=${page}&limit=${limit}&name=${name}`,
    null,
    true
  );
}

/**
 *
 * Not sure if Api work for @name = `null`, so this is kind of copied function
 * from @function getAllContest
 */
export function searchContestList({
  page = 1,
  limit = 50,
  status = 1,
  asset_type = 1,
  searchKey = "",
}) {
  return makeApiRequest(
    "GET",
    `admin/contests_list?page=${page}&limit=${limit}&status=${status}&asset_type=${asset_type}&name=${searchKey}`,
    null,
    true
  );
}

export function deleteContest(contestId) {
  return makeApiRequest(
    "DELETE",
    `admin/contests_delete?constestId=${contestId}`,
    null,
    true
  );
}

export function updateContest(contestId, stateData) {
  return makeApiRequest(
    "PUT",
    "/admin/updateContest",
    { contestId, ...stateData },
    true
  );
}

export function viewdata(brannerId) {
  return makeApiRequest(
    "GET",
    `/users/getBannerById?id=${brannerId}`,
    null,
    true
  );
}

//add term and policy
export function addTermsConditions(payload) {
  return makeApiRequest("PUT", "/admin/createTermsConditions", payload, true);
}

export function addPrivacyPolicy(payload) {
  return makeApiRequest("POST", "/users/postPrivacy", payload, true);
}

export function getPrivacyPolicyApi() {
  return makeApiRequest("GET", `/users/getPrivacy`, null, true)
}
export function confirmContest(contestId) {
  return makeApiRequest(
    "PUT",
    `admin/update_status?constestId=${contestId}`,
    null,
    true
  );
}

export function getContestById(contestId) {
  return makeApiRequest(
    "GET",
    `/admin/getContestDetails?constestId=${contestId}`,
    null,
    true
  );
}

export function getTradingSymbol() {
  return makeApiRequest(
    "GET",
    "instrument/getStockTradingSymbols",
    null,
    false,
    2
  );
}

export function getExchanges() {
  return makeApiRequest("GET", "instrument/getStockExchanges", null, false, 2);
}

export function getSegments() {
  return makeApiRequest("GET", "instrument/getStockSegments", null, false, 2);
}

export function getUserListData(page, search, type) {
  return makeApiRequest("GET", `/users/getAllUsersDetails`, null, true);
}
export function getReportListData(payload) {
  // return makeApiRequest(
  // 	"GET",
  // 	`/users/generateReport?fromDate=${payload.fromDate}&toDate=${payload.toDate}&module=${payload.Contesttype}`,
  // 	null,
  // 	true
  // );
  console.log(payload, "payload++++++++++");
}

export function getUserListById(id = "") {
  return makeApiRequest("GET", `/dropDown/getUsersProfileDataById?id=${id}`, null, true);
}

export function getMaritalStatus(id = "") {
  return makeApiRequest("GET", `/users/getMaritalStatus/${id}`, null, true);
}

export function getEnterContestListById(id, page, search) {
  return makeApiRequest(
    "GET",
    `admin/myupcomingcontest?userId=${id}&page=${page}&limit=50&name=${search}`,
    null,
    true
  );
}

export function getPastContestListById(id, page, search) {
  return makeApiRequest(
    "GET",
    `admin/pastContestList?userid=${id}&page=${page}&limit=50&name=${search}`,
    null,
    true
  );
}

export function getPastContestListForRefund(id) {
  return makeApiRequest(
    "GET",
    `admin/pastContestListForRefund?userid=${id}`,
    null,
    true
  );
}

export function getwithdrawListById(id, page, search) {
  return makeApiRequest("GET", `admin/getWallet?userId=${id}`, null, true);
}

export function getContestDetails(userId, contestId) {
  return makeApiRequest(
    "GET",
    `admin/ContestDetail?contestId=${contestId}&userId=${userId}`,
    null,
    true
  );
}

export function getKycProcess(id, status) {
  return makeApiRequest(
    "PUT",
    `admin/approvedDocuments?userid=${id}&status=${status}&comment=`,
    null,
    true
  );
}

export function getKycProcessReject(id, status, comment) {
  return makeApiRequest(
    "PUT",
    `admin/approvedDocuments?userid=${id}&status=${status}&comment=${comment}`,
    null,
    true
  );
}

export function getSuspendUser(id, status) {
  return makeApiRequest(
    "GET",
    `admin/suspendUser?userid=${id}&status=${status}`,
    null,
    true
  );
}

export function createPromotions(promotionFields) {
  return makeApiRequest(
    "POST",
    "promotion/save_promotions",
    promotionFields,
    true
  );
}

export function getPromotionList(page, limit, search) {
  return makeApiRequest(
    "GET",
    `promotion/promotions_list?page=${page}&limit=${limit}&name=${search}`,
    null,
    true
  );
}

export function deletePromotion(promotionId) {
  return makeApiRequest(
    "DELETE",
    `promotion/promotions_delete?promotionId=${promotionId}`,
    null,
    true
  );
}

export function getPromotionDetail(promotionId) {
  return makeApiRequest(
    "GET",
    `promotion/promotions_details?promotionId=${promotionId}`,
    null,
    true
  );
}

export function getWithdrawList(
  page,
  search,
  type,
  fromDate,
  toDate,
  limit = 50
) {
  return makeApiRequest(
    "GET",
    `admin/getwithdrawList?page=${page}&limit=${limit}&search=${search}&type=${type}&fromdate=${fromDate}&todate=${toDate}`,
    null,
    true
  );
}

export function putApprovalRejectStatus(userId, withdrawId, comment, status) {
  const body = {
    userid: userId,
    withdrawId: withdrawId,
    transactionId: 1,
    comment: comment,
    status: status,
  };
  return makeApiRequest("PUT", "admin/approvedWithdraw ", body, true);
}

export function putGroupApprovalRejectStatus(data, comment, status) {
  const body = {
    status: status,
    comment: comment,
    data: data,
  };
  return makeApiRequest("PUT", "admin/groupapprovedWithdraw ", body, true);
}

export function getCountryList() {
  return makeApiRequest("GET", `users/getCountries`, null, true);
}

export function getGroupList() {
  return makeApiRequest("GET", `admin/getGroups`, null, true);
}

export function getModuleReportList() {
  return makeApiRequest("GET", "/admin/getReportsURL", null, true);
}

export function getusercontest(id) {
  return makeApiRequest(
    "GET",
    `/contest/usersContest?UserId=${id}`,
    null,
    true
  );
}

export function generateAndSaveReports(payload) {
  return makeApiRequest(
    "GET",
    `/users/exportUser?currentDate=${payload.fromDate}&lastDate=${payload.toDate}&userType=${payload.userType}`,
    null,
    true
  );
}

export function generateReportsWithdraw(payload) {
  return makeApiRequest("POST", "payment/sheetWithdrawRequest", payload, true);
}

// export function getAdminDetails() {
//     return makeApiRequest("GET", "admin/getAdminDetails", null, true);
// }

export function createUser(payload) {
  return makeApiRequest("POST", "admin/registerByAdmin", payload, true);
}

export function refundMoney(payload) {
  return makeApiRequest("POST", "admin/refundMoney", payload, true);
}

export function addMoneyOrRefund(payload) {
  return makeApiRequest("POST", "admin/addMoneyOrRefund", payload, true);
}

export function updateUser(userId, stateData) {
  return makeApiRequest(
    "PUT",
    "admin/updateUser",
    { userId, ...stateData },
    true
  );
}

export function getLeaderBoardList(id) {
  return makeApiRequest(
    "GET",
    `admin/contestLeaderBoardList?contestid=${id}`,
    null,
    true
  );
}

export function getLeaderBoardListLive(id) {
  return makeApiRequest(
    "GET",
    `admin/liveContestDetailWithAllLeaderboard?contestid=${id}`,
    null,
    true
  );
}

export function getCountryListAll() {
  return makeApiRequest("GET", `users/getCountries`, null, true);
}

export function getStatesAll() {
  return makeApiRequest("GET", `users/getStates?name=`, null, true);
}

export function getMarketCapType() {
  return makeApiRequest("GET", `contests/getAllMarketCap`, null, true);
}

export function getDashboardData(fromDate, toDate) {
  return makeApiRequest("GET", `/users/totalNumberOfUsers`, null, true);
}

export function getImgBanner() {
  return makeApiRequest("GET", `/users/getImgBanner`, null, true);
}

export function updateStatusApi(payload) {
  return makeApiRequest("POST", `/users/usersStatus`, payload, true);
}

export function updateBannerAndValues(body) {
  return makeApiRequest("PUT", "/admin/updateBannerAndValues", body, true);
}

export async function getBannerImgUpload(file) {
  // console.log('file + = ',file)
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AUTH_TOKEN}`);

  var formdata = new FormData();
  formdata.append("file", file);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return fetch(`${API_BASE_URL}admin/bannerImgUpload`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      // console.log(result)
      return result ? JSON.parse(result) : {};
    })
    .catch((error) => {
      // console.log('error', error)
      return error ? JSON.parse(error) : {};
    });
}

export function toggleForWallet(body) {
  return makeApiRequest("PUT", "admin/toggleForWallet", body, true);
}

export function getBannerList() {
  return makeApiRequest("GET", "/users/getBannerList", null, true);
}

// export function addBanner(body) {
//     return makeApiRequest("POST", "/admin/addBanner", body, true);
// }

// export function addBanner(body) {
//   return uploadFile("POST", "/admin/addBanner", body, true);
// }

export function deleteBanner(id){
  return makeApiRequest("DELETE" , `/users/deleteBannerImage?id=${id}`)
}

export function addBanner(body) {
  return uploadFile("POST", "/users/bannerImages", body, true);
}
export function getAppConfiguration() {
  return makeApiRequest("GET", "/admin/AppConfiguration", null, true);
}

export function getAdminDetails() {
  return makeApiRequest("GET", "/admin/adminDetails", null, true);
}

export function changePassword(body) {
  return makeApiRequest("PUT", "/admin/updatePassword", body, true);
}
export function EditBanner(body,id) {
  return uploadFileEdit("PUT", `/users/editBannerImage?id=${id}`, body, true);
}

export function filterduplicate(contestId) {
  return makeApiRequest(
    "GET",
    `/admin/getContestDetails?constestId=${contestId}`,
    null,
    true
  );
}

export function updateAdminProfile(body) {
  return uploadFileEdit("PUT", "/admin/updateAdminProfile", body, true);
}

export function toggleForBanner(type, id) {
  return makeApiRequest(
    "PUT",
    `/admin/activateOrdeactivateBanner?id=${id}&type=${type}`,
    null,
    true
  );
}

export function acceptOrRejectKyc(documentType, userId, action) {
  let body = { documentType: documentType, userId: userId, action: action };
  return makeApiRequest("PUT", `/admin/acceptOrRejectKyc`, body, true);
}

export function updatecontestdata(documentType, userId, action) {
  let body = { documentType: documentType, userId: userId, action: action };
  return makeApiRequest("PUT", `/admin/acceptOrRejectKyc`, body, true);
}

export function getBodyTypedata(data) {
  let payload = { bodyType: data };
  return makeApiRequest("POST", `/dropDown/insertBodyType`, payload, true);
}

export function getPronounsdata(data) {
  let payload = { pronouns: data };
  return makeApiRequest("POST", `/dropDown/insertPronouns`, payload, true);
}
export function getDietdata(data) {
  let payload = { diet: data };
  return makeApiRequest("POST", `/dropDown/insertDiet`, payload, true);
}
export function getSmokingdata(data) {
  let payload = { smokingHabits: data };
  return makeApiRequest("POST", `/dropDown/insertSmokingHabits`, payload, true);
}
export function getDrinkingdata(data) {
  let payload = { drinkingHabits: data };
  return makeApiRequest("POST", `/dropDown/insertDrinkingHabits`, payload, true);
}
export function getGenderdata(data){
  let payload={gender: data};
  return makeApiRequest("POST", `/dropDown/insertgender`,payload , true);
}

export function getSexualitydata(data){
  let payload={sexuality: data};
  return makeApiRequest("POST", `/dropDown/insertSexuality`,payload , true);
}

export function getChildrendata(data) {
  let payload = { Children: data };

  return makeApiRequest("POST", `/dropdown/insertChildren`, payload, true);
}
export function getEthnicitydata(data) {
  let payload = { Ethnicity: data };

  return makeApiRequest("POST", `/dropDown/insertEthnicity`, payload, true);
}
export function getHobbiesdata(data) {
  let payload = { hobbies: data };

  return makeApiRequest("POST", `/dropDown/insertHobbies`, payload, true);
}
export function getHoroScopedata(data) {
  let payload = { horoScope: data };

  return makeApiRequest("POST", `/dropDown/insertHoroScope`, payload, true);
}
export function getindustrydata(data) {
  let payload = { industry: data };

  return makeApiRequest("POST", `/dropDown/insertIndustry`, payload, true);
}
export function getLanguagedata(data) {
  let payload = { language: data };

  return makeApiRequest("POST", `/dropDown/insertLanguage`, payload, true);
}

export function getlookingdata(data) {
  let payload = { lookingFor: data };

  return makeApiRequest("POST", `/dropDown/insertLookingFOr`, payload, true);
}
export function getVocationalTrainingdata(data) {
  let payload = { vocationalTraining: data };

  return makeApiRequest("POST", `/dropDown/insertVocationalTraining`, payload, true);
}
export function getExercisedata(data) {
  let payload = { exercise: data };

  return makeApiRequest("POST", `/dropDown/insertExercise`, payload, true);
}
export function getLoveLanguagedata(data) {
  let payload = { loveLanguage: data };

  return makeApiRequest("POST", `/dropDown/insertLoveLanguage`, payload, true);
}
export function getPetsdata(data) {
  let payload = { pets: data };

  return makeApiRequest("POST", `/dropDown/insertPets`, payload, true);
}
export function getProfessiondata(data) {
  let payload = { proffession: data };
  return makeApiRequest("POST", `/dropDown/insertProffession`, payload, true);
}
export function getReligiondata(data) {
  let payload = { religion: data };

  return makeApiRequest("POST", `/dropDown/insertReligion`, payload, true);
}
export function getUniversitydata(data) {
  let payload = { univercity: data };

  return makeApiRequest("POST", `/dropDown/insertUnivercity`, payload, true);
}
export function getEducationLeveldata(data) {
  let payload = { educationLevel: data };
  return makeApiRequest(
    "POST",
    `/dropDown/insertEducationLevel`,
    payload,
    true
  );
}
export function getInterestdata(data) {
  let payload = { interest: data };

  return makeApiRequest("POST", `/dropDown/insertInterest`, payload, true);
}
export function getMyersBriggsdata(data) {
  let payload = { myersBrigge: data };

  return makeApiRequest("POST", `/dropDown/insertMyersBriggs`, payload, true);
}
export function getMaritalStatusdata(data) {
  let payload = { Maritalstatus: data };

  return makeApiRequest("POST", `/dropDown/insertMaritalStatus`, payload, true);
}

export function getBodyTypeview() {
  return makeApiRequest("GET", `/dropDown/getAllBodyTypes`, null, true);
}
export function getSexualityview() {
  return makeApiRequest("GET", `/dropDown/getAllSexuality`, null, true);
}
export function getDietview() {
  return makeApiRequest("GET", `/dropDown/getAllDiet`, null, true);
}
export function getSmokingview() {
  return makeApiRequest("GET", `/dropDown/getAllSmokingHabits`, null, true);
}
export function getPronounsview() {
  return makeApiRequest("GET", `/dropDown/getAllPronouns`, null, true);
}
export function getDrinkingview() {
  return makeApiRequest("GET", `/dropDown/getAllDrinkingHabits`, null, true);
}
export function getChildrenview() {
  return makeApiRequest("GET", `/dropdown/getAllChildrens`, null, true);
}
export function getGenderview(){
  return makeApiRequest("GET",`/dropDown/getAllGenders`,null,true);
}
export function getEthnicityview() {
  return makeApiRequest("GET", `/dropDown/getAllEthnicity`, null, true);
}
export function getHobbiesview() {
  return makeApiRequest("GET", `/dropDown/getAllHobbies`, null, true);
}
export function getHoroScopeview() {
  return makeApiRequest("GET", `/dropDown/getAllHoroScope`, null, true);
}
export function getindustryview() {
  return makeApiRequest("GET", `/dropDown/getAllIndustries`, null, true);
}
export function getLanguageview() {
  return makeApiRequest("GET", `/dropDown/getAllLanguages`, null, true);
}
export function getLookingview() {
  return makeApiRequest("GET", `/dropDown/getAllLookingFor`, null, true);
}
export function getVocationalTrainingview() {
  return makeApiRequest("GET", `/dropDown/getAllVocationalTraining`, null, true);
}
export function getExerciseview() {
  return makeApiRequest("GET", `/dropDown/getAllExercises`, null, true);
}
export function getLoveLanguageview() {
  return makeApiRequest("GET", `/dropDown/getAllLoveLanguages`, null, true);
}
export function getPetsview() {
  return makeApiRequest("GET", `/dropDown/getAllPets`, null, true);
}
export function getProfessionview() {
  return makeApiRequest("GET", `/dropDown/getAllProfession`, null, true);
}
export function getReligionview() {
  return makeApiRequest("GET", `/dropDown/getAllReligions`, null, true);
}
export function getUniversityview() {
  return makeApiRequest("GET", `/dropDown/getAllUniversities`, null, true);
}
export function getEducationLevelview() {
  return makeApiRequest("GET", `/dropDown/getAllEducationLevel`, null, true);
}
export function getInterestview() {
  return makeApiRequest("GET", `/dropDown/getAllInterests`, null, true);
}
export function getMyersBriggsview() {
  return makeApiRequest("GET", `/dropDown/getAllMyersBriggs`, null, true);
}
export function getMaritalStatusview() {
  return makeApiRequest("GET", `/dropDown/getAllMaritalStatus`, null, true);
}

//  getBodyTypedelete,
//   getChildrendelete,
//   getEthnicitydelete,
//   getHobbiesdelete,
//   getHoroScopedelete,
//   getindustrydelete,
//   getLanguagedelete,
//   getLoveLanguagedelete,
//   getPetsdelete,
//   getProfessiondelete,
//   getReligiondelete,
//   getUniversitydelete,
//   getEducationLeveldelete,
//   getInterestdelete,
//   getMyersBriggsdelete,
//   getMaritalStatusdelete,
export function getBodyTypedelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteBodyType`, data, true);
}
export function getChildrendelete(data) {
  return makeApiRequest("PUT", `/dropdown/deleteChildren`, data, true);
}

export function getSexualitydelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteSexuality`, data, true);
}

export function getPronounsdelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteProunouns`, data, true);
}

export function getGenderdelete(data) {
	return makeApiRequest(
		"PUT",
		`/dropDown/deleteGender`,
		{
			id: data,
			isActive: false,
		},
		true
	);
}

export function getDietdelete(data) {
	return makeApiRequest(
		"PUT",
		`/dropDown/deleteDiet`,
		{
			id: data,
			isActive: false,
		},
		true
	);
}

export function getDrinkingdelete(data){
  return makeApiRequest("PUT",`dropDown/deleteDrinkingHabits`, data, true)
}
export function getSmokingdelete(data) {
	return makeApiRequest(
		"PUT",
		`/dropDown/deleteSmokingHabits`,
		{
			id: data,
			isActive: false,
		},
		true
	);
}
export function getHobbiesdelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteHobbies`, data, true);
}
export function getHoroScopedelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteHoroScope`, data, true);
}
export function getindustrydelete(data) {
  return makeApiRequest("PUT", `/dropDOwn/deleteIndustry`, data, true);
}
export function getLanguagedelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteLanguage`, data, true);
}
export function getPetsdelete(data) {
  return makeApiRequest("PUT", `/dropDown/deletePets`, data, true);
}
export function getProfessiondelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteProffession`, data, true);
}
export function getReligiondelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteReligion`, data, true);
}
export function getUniversitydelete(data) {
  return makeApiRequest("PUT", `/dropDown/insertUnivercity`, data, true);
}
export function getEducationLeveldelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteEducationLevel`, data, true);
}
export function getInterestdelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteInterest`, data, true);
}
export function getMyersBriggsdelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteMyersBriggs`, data, true);
}
export function getMaritalStatusdelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteMaritalStatus`, data, true);
}
export function getEthnicitydelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteEthnicity`, data, true);//////////
}
export function getLoveLanguagedelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteLoveLanguage`, data, true);
}
export function getLookingdelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteLookingFor`, data, true);
}export function getVocationalTrainingdelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteVocationalTrainng`, data, true);
}export function getExercisedelete(data) {
  return makeApiRequest("PUT", `/dropDown/deleteExercise`, data, true);
}


export function getPreferenceByData(payload){
  return makeApiRequest("POST" ,`/dropDown/getAlldropDownData`,payload , true);
}

export function updatePreference(payload){
  return makeApiRequest("POST",`/dropDown/editAlldropDownData`,payload,true)
}